/* General styles */
.wearable-container {
  margin: 0 auto;
  padding: 20px;
  padding-top: 10%;
  align-items: center;
  max-width: 1200px; /* Constrain the width for large screens */
}

/* Typography */
h1, h2 {
  margin-bottom: 20px;
}

.wearable-title {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: center;
}

.wearable-tagline {
  font-size: 1.2rem;
  color: #7f8c8d;
  text-align: center;
  margin-bottom: 40px;
}

/* Hero section */
.hero-wearable {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px; /* More spacing to separate hero section */
}

.hero-content {
  flex: 1;
  max-width: 45%; /* Keep the content on the left to a smaller width for balance */
  padding: 30px;
}

.hero-image {
  flex: 1;
  min-width: 300px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.9); /* Adjust scale for better alignment */
}

.hero-image img {
  max-width: 100%;
  height: auto;
  transform-origin: center center;
}

.hero-title {
  font-size: 2rem;
  color: #3498db;
}

.hero-description {
  font-size: 1.2rem; /* Slightly larger text for readability */
  margin-bottom: 20px;
}

.cta-button {
  display: inline-block;
  padding: 12px 24px; /* More padding for larger buttons */
  background-color: #2ecc71;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s; /* Smooth hover effects */
  font-weight: bold; /* Bold for CTA */
}

.cta-button:hover {
  background-color: #27ae60;
  transform: translateY(-2px); /* Button lift effect on hover */
}

/* Feature section */
.feature-section {
  margin-bottom: 60px; /* Increased spacing between sections */
}

.feature-list {
  list-style-type: none;
  padding-left: 0;
}

.feature-list li {
  margin-bottom: 15px;
  padding-left: 35px; /* More padding for better spacing */
  position: relative;
}

.feature-list li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #2ecc71;
  font-size: 1.5rem; /* Larger checkmark */
}

/* Integration steps */
.integration-steps ol {
  padding-left: 30px; /* More padding for ordered list */
  font-size: 1.1rem; /* Increase font size for readability */
}

.integration-steps li {
  margin-bottom: 15px;
}

/* Responsive design */
@media (max-width: 768px) {
  .hero-wearable {
    flex-direction: column;
    align-items: center;
  }

  .hero-content, .hero-image {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }

  .hero-content {
    padding: 20px;
  }

  .hero-image {
    margin-top: 20px;
    transform: scale(0.8); /* Slight scale-down on smaller screens */
  }

  .title {
    font-size: 2rem;
  }

  .hero-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.8rem;
  }

  .hero-title {
    font-size: 1.5rem;
  }

  .wearable-title {
    font-size: 2rem;
  }

  .wearable-tagline {
    font-size: 1rem;
  }

  .cta-button {
    display: block;
    width: 100%;
    text-align: center;
    padding: 15px; /* Larger padding on smaller devices */
    margin: 10px 0; /* Space between buttons in case multiple CTAs */
  }

  .feature-list li::before {
    font-size: 1.2rem; /* Smaller checkmark on mobile */
  }

  .hero-image {
    transform: scale(0.7); /* Scale image down further for mobile */
  }
}
