/* App.css 
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
General Styles */

  
  .section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 40px;
    padding: 4rem 0;
    background: #fff;
  }
  
  .section:nth-of-type(even) {
    background: #f5f5f5;
  }
  
  .content {
    flex: 1;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .image-container {
    flex: 1;
    text-align: center;
  }
  
  .image {
    max-width: 100%;
    height: auto;
  }
  
  
  .title {
    font-size: 60px;
    margin-bottom: 10px;
  }
    
  /* Values Styles */
  .values-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .values-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .value-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .value-text {
    font-size: 16px;
  }
  
  .description {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #333;
    margin-bottom: 1.5rem;
  }