.container {
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  }

.title {
  font-size: 48px;
  font-weight: bold;
  color: #FF6347;
  margin-bottom: 32px;
  align-items: center!important;
}

.description {
  font-size: 24px;
  font-weight: normal;
  color: #4A4A4A;
  margin-bottom: 16px;
  text-align: center;
}

.demo-links {
  font-size: 18px;
  font-weight: normal;
  color: #4A4A4A;
  margin-bottom: 32px;
  text-align: center;
}

.subscribe-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
}

.subscribe-input {
  width: 240px;
  height: 40px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 16px;
}

.subscribe-button {
  height: 40px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #0277bd;
  color: #FFFFFF;
  font-size: 16px;
  cursor: pointer;
}

.subscribe-button:hover {
  background-color: #01579b;
}


/* CSS for Animated Heartbeat Graphic */
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.heartbeat-graphic {
  width: 100px;
  height: 100px;
  background-color: #ff5733; /* Heartbeat color */
  border-radius: 50%;
  position: relative;
  animation: heartbeat 2s infinite;
}

.pulse-dot {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 87, 51, 0.5); /* Transparent heartbeat color */
  border-radius: 50%;
  position: absolute;
  top: 45px;
  left: 45px;
  transform-origin: center;
  animation: heartbeat 2s infinite 0.5s; /* Slightly delayed animation */
  filter: blur(10px); /* Apply a slight blur effect */
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 48px;
  color: #fff;
  margin-bottom: 20px;
}

.tagline {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
}


.info-section h2 {
  font-size: 32px;
  color: #0277bd;
  text-align: center;
}

.demo-link {
  display: block;
  background-color: #29b6f6;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.demo-link:hover {
  background-color: #4fc3f7;
}

.survey-link {
  background-color: #4fc3f7;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.survey-link:hover {
  background-color: #29b6f6;
}

@media (max-width: 768px) {
  .demo-grid {
    flex-direction: column;
    align-items: center;
  }
}
