.team-member-image {
    width: 300px;
    height: 300px;

}

.team-member {
    flex: 1; /* Each column takes an equal amount of space */
    margin: 0 10px; /* Add margin for spacing between columns */
}

.team-members{
    display: flex;
    width: auto;
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space columns evenly */
    align-items: flex-start; /* Align items at the top */
    max-width: 1200px; /* Set a maximum width for the container */
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px; /* Add some padding for spacing */
}

@media screen and (max-width: 768px) {
    .team-members {
        flex-direction: column; /* Change to a single column layout */
        align-items: center; /* Center items horizontally */
    }
    
}