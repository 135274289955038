.faq-container {
    margin: 20px;
    padding: 10px;
    width: 100%;
    max-width: 800px;
  }
  
  .faq-item {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .faq-item.open {
    background-color: #f9f9f9;
  }
  
  .faq-question {
    margin: 0;
    font-weight: bold;
  }
  
  .faq-answer {
    margin-top: 10px;
  }
  