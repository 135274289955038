.blog-post-container {
    padding: 12%;
  }
  
  .blog-post-title {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .blog-post-content {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
  }
  
  .blog-post-section {
    margin-bottom: 40px;
  }
  
  .blog-post-subtitle {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .blog-post-paragraph {
    margin-bottom: 20px;
  }
  
  .loading {
    text-align: center;
    font-size: 20px;
    color: #777;
    margin-top: 40px;
  }