.blog-posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .blog-post-block {
    width: 80vw;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease;
  }
  
  .blog-post-block:hover {
    transform: translateY(-5px);
  }
  
  .blog-post-link {
    text-decoration: none;
    color: #333;
  }
  
  .blog-post-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .blog-post-short {
    font-size: 16px;
    color: #777;
  }

  .blog-container {
    height: 100vh;
  }